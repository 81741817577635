import { useLoginUser } from '@/src/stores/LoginUser'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { PagePaths } from '../../../../utils/PagePaths'
import { Props } from './Layout'

export type Params = {
  title?: string
  visibleBack?: boolean
  onClickBack?: () => void
  zIndexInvalid?: boolean
  hideUserInfo?: boolean
  headerChildren?: any
  height?: string
}

export const useProps = (params: Params): Props => {
  const router = useRouter()
  const loginUser = useLoginUser().loginUser
  const name = loginUser?.name ?? ''
  const balance = loginUser?.terasBalance ?? 0
  const userIconUrl = loginUser?.userIconUrl ?? ''
  const userFrameUrl = loginUser?.userFrameUrl ?? ''
  const userTitle = loginUser?.userTitle ?? null

  const onClickBackButton = useCallback(() => {
    if (!!params.onClickBack) {
      params.onClickBack()
    } else {
      router.back()
    }
  }, [router, params])

  const onClickAccountIcon = useCallback(() => {
    router.push(PagePaths.settings())
  }, [router])

  const onClickTitle = useCallback(() => {
    router.push(PagePaths.changeTitle())
  }, [router])

  const visibleBackButton = params.visibleBack ?? false
  const hideUserInfo = params.hideUserInfo ?? false
  return {
    title: params.title,
    visibleBackButton,
    hideUserInfo,
    onClickAccountIcon,
    onClickBackButton,
    onClickTitle,
    zIndexInvalid: params.zIndexInvalid,
    name,
    balance,
    userIconUrl,
    userFrameUrl,
    userTitle,
    headerChildren: params.headerChildren,
    height: params.height,
  }
}
