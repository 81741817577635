import React, { PropsWithChildren } from 'react'
import { useRouterPrefetch } from '../../../hooks/useRouterPrefetch'
import { PagePaths } from '../../../utils/PagePaths'
import { VStack } from '../bases/VStack'
import { BottomTabBar } from './BottomTabBar'
import { NavigationHeader, Props as NavigationHeaderProps } from './NavigationHeader'

type Props = {
  disabledTopMargin?: boolean
  hideBottomTab?: boolean
} & NavigationHeaderProps

export const GlobalNavigationLayout: React.FC<PropsWithChildren<Props>> = (props) => {
  useRouterPrefetch(PagePaths.settings())

  return (
    <VStack className="min-h-screen w-full min-w-full">
      <NavigationHeader
        visibleBack={props.visibleBack}
        onClickBack={props.onClickBack}
        title={props.title}
        zIndexInvalid={props.zIndexInvalid}
        hideUserInfo={props.hideUserInfo}
        headerChildren={props.headerChildren}
        height={props.height}
      />
      <VStack className="min-h-screen pt-[env(safe-area-inset-top)] pb-[env(safe-area-inset-bottom)]">
        <VStack
          className={`min-h-screen ${props.disabledTopMargin ? '' : props.title ? 'pt-28' : 'pt-16'} pb-16`}
        >
          {props.children}
        </VStack>
      </VStack>
      {!props.hideBottomTab ? <BottomTabBar /> : null}
    </VStack>
  )
}
