import Image from 'next/image'
import React from 'react'
import { Absolute } from '../bases/Absolute'
import { Center } from '../bases/Center'
import { Clickable } from '../bases/Clickable'

type Props = {
  normalImagUrl: string
  activeImageUrl: string
  isActive?: boolean
}

export const BottomTab: React.FC<Props> = (props) => {
  return (
    <Center className="relative h-full w-full items-center justify-center">
      <Clickable className="z-10 p-1">
        <Center className="aspect-square w-8">
          <Image
            src={props.isActive ? props.activeImageUrl : props.normalImagUrl}
            layout="fill"
            alt={props.normalImagUrl}
          />
        </Center>
      </Clickable>
      <Absolute
        className={`left-0 bottom-0 z-0 aspect-[336/162] h-full w-full items-center justify-center ${
          props.isActive ? 'opacity-50' : 'opacity-0'
        }`}>
        <Image priority src="/images/bottomTabIndicator.png" layout="fill" alt="TabIndicator" />
      </Absolute>
    </Center>
  )
}
