import React from 'react'
import { Absolute } from '../bases/Absolute'
import { VStack } from '../bases/VStack'
import { Center } from '../bases/Center'
import { Text } from '../bases/Text'
import { PinkNeonButton } from '../buttons/PinkNeonButton'

export type RewardResult = {
  id: string
  itemType: 'TERAS' | 'BORDER' | 'NO_BORDER'
  imageUrl?: string
  label: string
}

export type RewardDisplay = 'Result_One' | 'Result_Multi'

type Props = {
  status: RewardDisplay
  resultList: Array<RewardResult | null>
  resultIndex: number
  onClickResult?: () => void
  buttonInfo?: {
    title: string
    onClick: () => void
  }
}

export const RewardResultView: React.VFC<Props> = (props) => {
  return (
    <>
      <Absolute className="rotate_view left-[-100%] h-full w-[300%]">
        <img src={'/images/rewardLight.png'} className="w-full" alt="" />
      </Absolute>

      <Absolute className="h-screen w-screen bg-black/40 backdrop-blur-[5px]">
        <VStack
          className="h-full w-full items-center"
          onClick={() => props.onClickResult && props.onClickResult()}
        >
          <Absolute className="top-14">
            <VStack className="text-center">
              <Text className="whitespace-nowrap text-[34px] font-bold text-white">Reward</Text>
              <Text className="mt-4 text-sm font-bold text-white">You have gained these items!</Text>
            </VStack>
          </Absolute>
          <Center className="flex-1 px-8">
            {props.status === 'Result_One' ? (
              <VStack
                key={props.resultList[props.resultIndex]?.id}
                className="zoom-in-view mt-10 items-center gap-y-5"
              >
                {props.resultList[props.resultIndex]?.itemType === 'TERAS' ? (
                  <img src={'/images/terasTypeResult.png'} width={145} height={145} alt="" />
                ) : props.resultList[props.resultIndex]?.itemType === 'NO_BORDER' ? (
                  props.resultList[props.resultIndex]?.imageUrl && (
                    <Center className="overflow-hidden object-contain">
                      <img
                        src={props.resultList[props.resultIndex]?.imageUrl ?? ''}
                        width={85}
                        height={85}
                        alt=""
                      />
                    </Center>
                  )
                ) : (
                  props.resultList[props.resultIndex]?.imageUrl && (
                    <Center className={`overflow-hidden rounded-md border border-[#805BE7]`}>
                      <img
                        src={props.resultList[props.resultIndex]?.imageUrl ?? ''}
                        width={145}
                        height={145}
                        alt=""
                      />
                    </Center>
                  )
                )}
                <Text className="whitespace-nowrap text-2xl font-bold text-neon-blue">
                  {props.resultList[props.resultIndex]?.label || ''}
                </Text>
              </VStack>
            ) : (
              <div className="grid grid-cols-3 gap-4">
                {props.resultList.map((result, index) =>
                  result && index < 9 ? (
                    <VStack key={result.id} className="zoom-in-view h-[124px] items-center gap-y-1">
                      {result.itemType === 'TERAS' ? (
                        <img src={'/images/terasTypeResult.png'} height={86} width={86} alt="" />
                      ) : (
                        result?.imageUrl && (
                          <Center
                            className={`overflow-hidden rounded-md ${
                              result.itemType === 'BORDER' ? 'border border-[#805BE7]' : ''
                            }`}
                          >
                            <img src={result?.imageUrl ?? ''} height={86} width={86} alt="" />
                          </Center>
                        )
                      )}
                      <Text className="text-center text-xs font-bold text-neon-blue">
                        {result?.label || ''}
                      </Text>
                    </VStack>
                  ) : !result && index < 9 ? (
                    <div key={index} className="h-[124px]" />
                  ) : (
                    <div key={index} />
                  ),
                )}
              </div>
            )}
          </Center>
        </VStack>
        {props.buttonInfo && props.resultList.length === props.resultIndex + 1 && (
          <Absolute className="bottom-[20%]">
            <Center className="w-screen">
              <PinkNeonButton onClick={props.buttonInfo.onClick}>
                <Text className="text-xl font-semibold text-white">{props.buttonInfo.title}</Text>
              </PinkNeonButton>
            </Center>
          </Absolute>
        )}
      </Absolute>
    </>
  )
}
