import React from 'react'
import Image from 'next/image'
import { HStack } from '../bases/HStack'
import { Center } from '../bases/Center'
import { Text } from '../bases/Text'

type State = 'Default' | 'WidthFull' | 'Small'

export type Props = {
  state: State
  title: string
  icon: string
  startColor: string
  endColor: string
  middlePoint: string
  disabled?: boolean
}

export const UserTitleCell = (props: Props) => {
  // TODO: 角度調整
  const getTitleColor = (startColor: string, middlePoint: string, endColor: string) =>
    `linear-gradient(135deg, ${startColor} ${middlePoint}, ${endColor} 100%)`
  return (
    <HStack
      className={`items-center justify-center ${props.state === 'WidthFull' ? 'w-full' : 'w-fit'} ${
        props.disabled ? 'opacity-40' : ''
      }`}
    >
      <Center className="h-fit w-fit">
        {props.icon && (
          <Image
            src={props.icon}
            height={props.state === 'Small' ? 18 : 34}
            width={props.state === 'Small' ? 18 : 34}
            alt="icon"
            className="z-10 object-contain"
          />
        )}
      </Center>
      <Center
        className="z-0 rounded py-1"
        style={{
          background: getTitleColor(props.startColor, props.middlePoint, props.endColor),
          width: props.state === 'WidthFull' ? '100%' : 'fit-content',
          height: props.state === 'Small' ? 14 : 30,
          marginLeft: props.state === 'Small' ? -10 : -20,
          paddingLeft: props.state === 'Small' ? 14 : 28,
          paddingRight: props.state === 'Small' ? 4 : 8,
        }}
      >
        <Text className="font-bold text-white" style={{ fontSize: props.state === 'Small' ? 10 : 18 }}>
          {props.title}
        </Text>
      </Center>
    </HStack>
  )
}
