import { useCallback, useEffect, useState } from 'react'
import { Props } from './Layout'
import { useCurrentUserRewardsQuery, useAcceptRewardAllMutation, SortOrder } from './query.generated'
import { S3URLService } from '@/src/services/S3URLService'
import { useLoginUser } from '@/src/stores/LoginUser'
import { convertDisplayDate, getAgo } from '@/src/utils/DateUtil'

export const useProps = (): Props => {
  const loginUser = useLoginUser().loginUser
  const [now, setNow] = useState(new Date())
  const [visible, setVisible] = useState(false)
  const [status, setStatus] = useState<Props['status']>('List')
  const [selectTab, setSelectTab] = useState<Props['selectTab']>('Available')
  const [availableDataList, setAvailableDataList] = useState<Props['availableDataList']>([])
  const [acceptedDataList, setAcceptedDataList] = useState<Props['acceptedDataList']>([])
  const [baseResultList, setBaseResultList] = useState<Props['resultList']>([])
  const [resultList, setResultList] = useState<Props['resultList']>([])
  const [resultIndex, setResultIndex] = useState<Props['resultIndex']>(0)

  const [receiveRewards] = useAcceptRewardAllMutation()

  const { data, refetch } = useCurrentUserRewardsQuery({
    variables: {
      availableWhere: {
        acceptedAt: { equals: null },
        OR: [{ availableUntil: null }, { availableUntil: { gte: now } }],
      },
      acceptedWhere: { acceptedAt: { not: { equals: null } } },
      orderBy: { createdAt: SortOrder.Desc },
    },
  })

  const doRefetch = useCallback(async () => {
    // 読み込み中はLoadingにする
    setStatus('Loading')
    setNow(new Date())
    // 再読み込み
    await Promise.all([refetch(), loginUser?.reload()])
    setStatus('List')
  }, [refetch])

  useEffect(() => {
    setSelectTab('Available')
    doRefetch()
  }, [visible, doRefetch])

  useEffect(() => {
    setAvailableDataList(
      (data?.available || [])
        .filter((e) => !!!e.acceptedAt)
        .map((e) => ({
          id: e.id,
          itemType: e.rewardItemType,
          title: e.title,
          name: `${e.name} x${e.amount}`,
          amount: e.amount,
          imageUrl: S3URLService.getRewardURL(e.rewardItemType, e.category, e.subCategory),
          ago: getAgo(e.createdAt),
          availableUntil: e.availableUntil ? convertDisplayDate(e.availableUntil) : undefined,
          isBorder:
            e.rewardItemType !== 'TERAS'
              ? !(e.rewardItemType === 'COLLECTIBLE_ITEM' && ['TITLE', 'FRAME'].includes(e.category))
              : false,
        })) ?? [],
    )
    setAcceptedDataList(
      (data?.accepted || [])
        .filter((e) => !!e.acceptedAt)
        .map((e) => ({
          id: e.id,
          itemType: e.rewardItemType,
          title: e.title,
          name: `${e.name} x${e.amount}`,
          amount: e.amount,
          receivedDate: convertDisplayDate(e.acceptedAt),
        })) ?? [],
    )
  }, [data, doRefetch])

  const onClickAccept = async () => {
    setStatus('Loading')

    receiveRewards()
      .then(async (res) => {
        if (res.errors) {
          alert(res?.errors?.[0]?.message ? res.errors[0].message : 'Error')
          setStatus('List')
        } else {
          setResultIndex(0)
          const result = res.data?.acceptRewardAll.rewards ?? []

          const results: Props['resultList'] = result.map((e, i) => ({
            id: `result_${i}`,
            itemType:
              'TERAS' === e.itemType
                ? 'TERAS'
                : !(e.itemType === 'COLLECTIBLE_ITEM' && (e.category === 'TITLE' || e.category === 'FRAME'))
                ? 'BORDER'
                : 'NO_BORDER',
            imageUrl: S3URLService.getRewardURL(e.itemType, e.category, e.subCategory),
            label:
              e.itemType === 'TERAS'
                ? `${e.amount.toLocaleString()} Teras`
                : `${e.name || e.category + ' ' + e.subCategory} x${e.amount}`,
          }))

          setBaseResultList(results)

          if (result.length >= 3) {
            setStatus('Result_Multi')
            setResultList([...Array(results.length)])
            bulkPush(results.filter((e) => !!e))
          } else {
            setStatus('Result_One')
            setResultList(results)
          }
        }
      })
      .catch((error) => {
        alert(error.message ? error.message : 'Error')
        setStatus('List')
      })
  }

  const bulkPush = async (list: Props['resultList']) => {
    const resultSetter = (result: Props['resultList'] | undefined, index: number, displayIndex: number) => {
      if (!result) return
      const newResult: Props['resultList'] = [...Array(displayIndex).fill(null)]
      for (let i = 0; i <= index; i++) {
        newResult[i] = result?.[i] ?? null
      }
      setResultList(newResult.fill(null, index + 1, result.length))
    }

    // 9個（画面表示数分）ずつ分解する
    const sliceList = new Array(Math.ceil(list.length / 9))
      .fill(undefined)
      .map((_, i) => list.slice(i * 9, (i + 1) * 9))
    for (const [i, result] of Object.entries(list)) {
      if (result === null) continue

      await new Promise((resolve) => {
        const remainder = Number(i) % 9
        // ページを跨ぐ場合は待ち時間を大きくする
        const ms = Number(i) !== 0 && remainder === 0 ? 1000 : 200
        const displayIndex = list.length <= 3 ? 3 : list.length <= 6 ? 6 : 9
        setTimeout(
          () => resolve(resultSetter(sliceList[Math.floor(Number(i) / 9)], remainder, displayIndex)),
          ms,
        )
      })
    }
  }

  const onClickResult = async () => {
    if (status === 'Result_One') {
      if (resultIndex + 1 === baseResultList.length) {
        // 表示終了
        doRefetch()
      } else {
        // 次のアイテム表示
        setResultIndex(resultIndex + 1)
      }
    } else if (status === 'Result_Multi') {
      // 表示を中断して終了
      doRefetch()
    }
  }

  return {
    visible,
    status,
    selectTab,
    canRecieve: availableDataList.length > 0,
    onClickOpen: () => setVisible(true),
    onClickClose: () => setVisible(false),
    onClickTab: (tab) => setSelectTab(tab),
    onClickAccept,
    availableDataList,
    acceptedDataList,
    resultList,
    onClickResult,
    resultIndex,
  }
}
