import React from 'react'
import { Center } from '../../bases/Center'
import { Fixed } from '../../bases/Fixed'
import { HStack } from '../../bases/HStack'
import { VStack } from '../../bases/VStack'
import { BottomTab } from '../BottomTab'
import Link from 'next/link'
import { PagePaths } from '@/src/utils/PagePaths'

export type Props = {
  activeTab: 'home' | 'ranking' | 'wallet'
}

export const Layout: React.FC<Props> = (props) => {
  return (
    <Fixed className="bottom-0 left-0 z-10 w-full backdrop-blur-lg">
      <VStack>
        <HStack className="h-16 w-full bg-[rgba(0,0,0,0.7)] px-8">
          <Center className="flex-1">
            <Link href={PagePaths.top()}>
              <a className="flex h-full w-full">
                <BottomTab
                  isActive={props.activeTab === 'home'}
                  normalImagUrl="/images/bottomTabHome.png"
                  activeImageUrl="/images/bottomTabHomeActive.png"
                />
              </a>
            </Link>
          </Center>
          <Center className="flex-1">
            <Link href={PagePaths.ranking()}>
              <a className="flex h-full w-full">
                <BottomTab
                  isActive={props.activeTab === 'ranking'}
                  normalImagUrl="/images/bottomTabRanking.png"
                  activeImageUrl="/images/bottomTabRankingActive.png"
                />
              </a>
            </Link>
          </Center>
          <Center className="flex-1">
            <Link href={PagePaths.wallet()}>
              <a className="flex h-full w-full">
                <BottomTab
                  isActive={props.activeTab === 'wallet'}
                  normalImagUrl="/images/bottomTabWallet.png"
                  activeImageUrl="/images/bottomTabWalletActive.png"
                />
              </a>
            </Link>
          </Center>
        </HStack>
        <VStack className="h-[env(safe-area-inset-bottom)] w-full bg-black" />
      </VStack>
    </Fixed>
  )
}
