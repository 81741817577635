import Image from 'next/image'
import React, { PropsWithChildren } from 'react'
import { Center } from '../../bases/Center'
import { Clickable } from '../../bases/Clickable'
import { Fixed } from '../../bases/Fixed'
import { HStack } from '../../bases/HStack'
import { Text } from '../../bases/Text'
import { VStack } from '../../bases/VStack'
import Teras from '../../../../../public/images/teras.png'
import Link from 'next/link'
import { PagePaths } from '@/src/utils/PagePaths'
import { Absolute } from '../../bases/Absolute'
import { PresentRecieveModalButton } from '../../modals/PresentRecieveModal'
import { UserTitleCell } from '../../commons/UserTitleCell'

export type Props = {
  title?: string
  visibleBackButton: boolean
  hideUserInfo: boolean
  onClickBackButton: () => void
  onClickAccountIcon: () => void
  onClickTitle: () => void
  zIndexInvalid?: boolean
  name: string
  balance: number
  userIconUrl: string
  userFrameUrl: string
  userTitle: { title: string; icon: string; startColor: string; endColor: string; middlePoint: string } | null
  headerChildren?: any
  height?: string
}

export const Layout: React.FC<PropsWithChildren<Props>> = (props) => {
  const showTitleBar = props.visibleBackButton || props.title
  const height = props.height ? props.height : showTitleBar ? 'h-24' : 'h-14'
  return (
    <Fixed
      className={`top-0 left-0 w-full flex-col pt-[env(safe-area-inset-top)] ${
        props.zIndexInvalid ? '' : 'z-50'
      } ${height}`}
    >
      <HStack className="w-full pt-3">
        <HStack className="flex-1 items-center justify-between">
          <Link href={PagePaths.wallet()}>
            <a className="flex">
              <HStack className="ml-6 h-[36px] items-center rounded-[18px] bg-black px-2">
                <Image src={Teras} width={30} height={30} objectFit="fill" alt="" />
                <Text className="ml-2 overflow-hidden text-ellipsis whitespace-nowrap break-words text-xl font-bold text-white">
                  {props.balance.toLocaleString()}
                </Text>
              </HStack>
            </a>
          </Link>
          {!props.hideUserInfo && (
            <HStack className="items-center">
              <PresentRecieveModalButton />
              <VStack className="items-end gap-y-1" onClick={props.onClickTitle}>
                {props.userTitle && (
                  <UserTitleCell
                    state="Small"
                    title={props.userTitle?.title}
                    icon={props.userTitle?.icon}
                    startColor={props.userTitle?.startColor}
                    endColor={props.userTitle?.endColor}
                    middlePoint={props.userTitle?.middlePoint}
                  />
                )}
                <Text className="text-xs font-bold text-white">{props.name}</Text>
              </VStack>
              <Clickable className="h-full items-center px-4" onClick={props.onClickAccountIcon}>
                <Center className="relative h-9 w-9">
                  <Absolute>
                    <Center className="overflow-hidden rounded-full">
                      <Image
                        width={32}
                        height={32}
                        objectFit="fill"
                        alt="user icon"
                        src={props.userIconUrl}
                      />
                    </Center>
                  </Absolute>
                  <Image width={36} height={36} objectFit="fill" alt="user icon" src={props.userFrameUrl} />
                </Center>
              </Clickable>
            </HStack>
          )}
        </HStack>
      </HStack>
      {showTitleBar && (
        <HStack className="w-full flex-1">
          <VStack className="flex-1">
            {props.visibleBackButton ? (
              <Clickable className="h-full items-center px-4" onClick={props.onClickBackButton}>
                <Center className="relative aspect-square w-6">
                  <Image priority src={'/images/backIcon.png'} layout="fill" alt="AccountIcon" />
                </Center>
              </Clickable>
            ) : null}
          </VStack>
          <Center className="relative w-3/5">
            <Text className="overflow-hidden text-ellipsis whitespace-nowrap break-words text-xl font-bold text-white">
              {props.title}
            </Text>
          </Center>
          <VStack className="flex-1 items-end"></VStack>
        </HStack>
      )}
      {props.headerChildren}
    </Fixed>
  )
}
