import React from 'react'
import Image from 'next/image'
import { Clickable } from '../../bases/Clickable'
import { Fixed } from '../../bases/Fixed'
import { VStack } from '../../bases/VStack'
import { Center } from '../../bases/Center'
import { Absolute } from '../../bases/Absolute'
import { Text } from '../../bases/Text'
import { HStack } from '../../bases/HStack'
import { PinkNeonButton } from '../../buttons/PinkNeonButton'
import Teras from '@/public/images/teras.png'
import { ActivityIndicaotr } from '../../bases/Indicator'
import { RewardItemType } from './query.generated'
import { RewardResult, RewardResultView } from '../../commons/RewardResultView'

type TabType = 'Available' | 'Accepted'

type PresentData = {
  id: string
  itemType: RewardItemType
  title: string
  name?: string
  amount: number
  imageUrl?: string
  availableUntil?: string
  ago: string
  isBorder: boolean
}

type RecievedData = {
  id: string
  itemType: RewardItemType
  title: string
  name?: string
  amount: number
  receivedDate: string
}

export type Props = {
  visible: boolean
  status: 'List' | 'Loading' | 'Result_One' | 'Result_Multi'
  canRecieve: boolean
  selectTab: TabType
  onClickOpen: () => void
  onClickClose: () => void
  onClickTab: (tab: TabType) => void
  onClickAccept: () => void
  availableDataList: Array<PresentData>
  acceptedDataList: Array<RecievedData>
  resultList: Array<RewardResult | null>
  onClickResult: () => void
  resultIndex: number
}

export const Layout = (props: Props) => {
  const isNoData =
    props.selectTab === 'Available'
      ? props.availableDataList.length === 0
      : props.selectTab === 'Accepted'
      ? props.acceptedDataList.length === 0
      : false
  return (
    <>
      <Clickable className="mr-6 h-full items-center" onClick={props.onClickOpen}>
        <Center className="relative aspect-square w-6">
          <Image priority src={'/images/present.png'} alt="PresentIcon" height={24} width={24} />
        </Center>
        {props.canRecieve && (
          <Absolute className="top-0 -right-2 h-3 w-3">
            <Image priority src={'/images/iconExclamation.png'} alt="Exclamation" height={12} width={12} />
          </Absolute>
        )}
      </Clickable>
      {props.visible ? (
        <Fixed className="top-0 left-0 z-[150] h-full w-full overflow-hidden overscroll-contain bg-black bg-opacity-70 pt-[env(safe-area-inset-top)] pb-[env(safe-area-inset-bottom)]">
          <style global jsx>{`
            body,
            html {
              overflow: hidden;
            }
          `}</style>
          <VStack className="my-20 mx-6 rounded-[10px] border border-neon-blue bg-black pl-4 pr-2">
            <Text className="mt-4 text-center text-lg font-bold text-neon-blue">Rewards for you</Text>
            {props.status === 'Loading' ? (
              <Center className="h-full w-full">
                <ActivityIndicaotr />
              </Center>
            ) : (
              <>
                <HStack className="mt-2.5 items-center justify-around pt-1.5">
                  <Tab
                    tabType="Available"
                    selectTab={props.selectTab}
                    onClick={props.onClickTab}
                    badge={props.availableDataList.length}
                  />
                  <Tab tabType="Accepted" selectTab={props.selectTab} onClick={props.onClickTab} />
                </HStack>
                <VStack
                  className={`gray_scroll_bar flex-1 overflow-x-hidden overflow-y-scroll pt-4 ${
                    isNoData ? 'justify-center' : ''
                  }`}
                >
                  {isNoData ? (
                    <NoData text={props.selectTab === 'Available' ? 'There are no rewards available.' : ''} />
                  ) : props.selectTab === 'Available' ? (
                    props.availableDataList.map((data) => (
                      <VStack key={data.id}>
                        <Text
                          className="mr-1 break-all rounded-[3px] px-2 py-1 text-xs font-bold text-white"
                          style={{ background: 'linear-gradient(180deg, #805BE7 0%, #805BE7 100%)' }}
                        >
                          {data.title}
                        </Text>
                        {data.itemType === 'TERAS' ? (
                          <VStack className="mt-2 mb-4 px-2">
                            <HStack className="items-center justify-between">
                              <HStack className="items-center">
                                <Image src={Teras} width={20} height={20} objectFit="contain" alt="" />
                                <Text className="ml-1 text-base text-neon-blue">
                                  {data.amount.toLocaleString()}
                                </Text>
                              </HStack>
                              <Text className="whitespace-nowrap text-xs text-white/60">{data.ago}</Text>
                            </HStack>
                            {data.availableUntil && (
                              <Text className="mt-2 whitespace-nowrap text-[10px] font-bold text-white/60">
                                Available Until : {data.availableUntil}
                              </Text>
                            )}
                          </VStack>
                        ) : (
                          <HStack className="mt-2 mb-4 px-2">
                            <VStack className="justify-between gap-y-1">
                              <Text className="text-base font-bold text-neon-blue">{data.name}</Text>
                              {data.availableUntil && (
                                <Text className="whitespace-nowrap text-[10px] font-bold text-white/60">
                                  Available Until : {data.availableUntil}
                                </Text>
                              )}
                            </VStack>
                            <div className="relative flex w-11 flex-col items-end gap-y-1">
                              {data.imageUrl && (
                                <Center
                                  className={`w-11 overflow-hidden rounded-md ${
                                    data.isBorder ? 'border border-[#805BE7]' : ''
                                  }`}
                                >
                                  <Image
                                    src={data.imageUrl}
                                    width={44}
                                    height={44}
                                    objectFit="contain"
                                    alt=""
                                  />
                                </Center>
                              )}
                              <Text className="whitespace-nowrap text-xs text-white/60">{data.ago}</Text>
                            </div>
                          </HStack>
                        )}
                      </VStack>
                    ))
                  ) : (
                    props.acceptedDataList.map((data) => (
                      <VStack key={data.id}>
                        <Text className="mr-1 break-all text-xs font-bold text-white">{data.title}</Text>
                        {data.itemType === 'TERAS' ? (
                          <HStack className="mt-2 mb-4 items-center justify-between px-2">
                            <HStack className="w-full items-center">
                              <Image src={Teras} width={20} height={20} objectFit="contain" alt="" />
                              <Text className="ml-1 text-base text-neon-blue">
                                {data.amount.toLocaleString()}
                              </Text>
                            </HStack>
                            <Text className="ml-2 whitespace-nowrap text-xs text-white/60">
                              {data.receivedDate}
                            </Text>
                          </HStack>
                        ) : (
                          <HStack className="mt-2 mb-4 items-end justify-between px-2">
                            <Text className="w-full text-base font-bold text-neon-blue">{data.name}</Text>
                            <Text className="ml-2 whitespace-nowrap text-xs text-white/60">
                              {data.receivedDate}
                            </Text>
                          </HStack>
                        )}
                        <Center className="mb-2 mr-1 h-[1px] bg-[#52369F]" />
                      </VStack>
                    ))
                  )}
                </VStack>
                <Center className="relative mb-10 mt-4">
                  {props.selectTab === 'Available' && (
                    <PinkNeonButton onClick={props.onClickAccept} disabled={isNoData}>
                      <Center className="px-10">
                        <Text className="text-xl font-bold text-white">Accept</Text>
                      </Center>
                    </PinkNeonButton>
                  )}
                </Center>
              </>
            )}
            <Absolute className="right-2.5 bottom-2.5">
              <Clickable onClick={props.onClickClose}>
                <Center className="w-8">
                  <img src="/images/btn_cancel.png" alt="" />
                </Center>
              </Clickable>
            </Absolute>
          </VStack>

          {/** Result処理 */}
          {props.resultList.length > 0 &&
            (props.status === 'Result_One' || props.status === 'Result_Multi') && (
              <RewardResultView
                status={props.status}
                resultList={props.resultList}
                onClickResult={props.onClickResult}
                resultIndex={props.resultIndex}
              />
            )}
        </Fixed>
      ) : null}
    </>
  )
}

const Tab = (props: {
  tabType: TabType
  selectTab: TabType
  onClick: (tab: TabType) => void
  badge?: number
}) => {
  return (
    <Clickable onClick={() => props.onClick(props.tabType)}>
      <VStack className="w-[118px] gap-y-2.5">
        <Text className="text-center text-base text-neon-blue">{props.tabType}</Text>
        <Center className={`h-0.5 ${props.selectTab === props.tabType ? 'bg-neon-blue' : ''}`} />
        {!!props.badge && (
          <Absolute className="top-0 -right-5 h-4 min-w-[16px] items-center justify-center rounded-full bg-akiverse-pink">
            <Text className="w-full text-center text-xs font-bold text-white">{props.badge}</Text>
          </Absolute>
        )}
      </VStack>
    </Clickable>
  )
}

const NoData = (props: { text: string }) => (
  <Center className="w-full flex-col">
    <Image src={'/images/nodata.png'} width={120} height={120} alt={'No Data'} />
    <span className="mt-2 text-sm text-neutral-400">{props.text}</span>
  </Center>
)
