import dayjs from 'dayjs'
import { TERM_TIME_ZONE, UTC_TIME_ZONE } from '../constants'

/**
 * 現在時刻から基準日までの残り時間を返却する
 *
 * @param date 基準日
 * @returns 残り時間
 */
export const calcAgo = (date: Date): string => {
  const minuteFull = dayjs(date, TERM_TIME_ZONE).diff(dayjs(), 'm')
  const day = Math.floor(minuteFull / 60 / 24)
  const hour = Math.floor((minuteFull - day * 24 * 60) / 60)
  const minute = Math.floor(minuteFull - day * 24 * 60 - hour * 60)
  return `${day.toString()}day ${`00${hour}`.slice(-2)}:${`00${minute}`.slice(-2)}`
}

/**
 * 時刻を00時00分00秒に設定する
 *
 * @param date 基準日
 * @returns 変換結果
 */
export const setMidnight = (date: dayjs.Dayjs): Date => date.hour(0).minute(0).second(0).toDate()

/**
 * 時刻を23時59分59秒に設定する
 *
 * @param date 基準日
 * @returns 変換結果
 */
export const setEndOfDay = (date: dayjs.Dayjs): Date => date.hour(23).minute(59).second(59).toDate()

/**
 * DB検索用日付取得
 */
export const SearchDate = () => dayjs.tz(dayjs().toDate(), UTC_TIME_ZONE).toDate()

/**
 * 現在時刻取得
 */
export const getNowDate = () => dayjs.tz(dayjs().toDate(), TERM_TIME_ZONE)

/**
 * YYYY/MM/DD HH:mmに変換する
 * @param date 日付
 */
export const convertDisplayDate = (date?: string | number | dayjs.Dayjs | Date | null | undefined) =>
  formatDate(date, 'YYYY/MM/DD HH:mm')

/**
 * 表示フォーマット形式の文字列に変換する
 * @param date 日付
 * @param format フォーマット
 */
export const formatDate = (date: string | number | dayjs.Dayjs | Date | null | undefined, format: string) =>
  dayjs(date).format(format)

/**
 * 日付からの日時文字列
 * @param date 日付
 */
export const getAgo = (date: string | number | dayjs.Dayjs | Date | null | undefined) => dayjs(date).fromNow()

/**
 * ランキング画面表示用に日付を変換する
 * @param date 日付
 */
export const convertRankingDate = (date: string | number | dayjs.Dayjs | Date | null | undefined) =>
  dayjs.tz(date, TERM_TIME_ZONE).format('M/D HH:mm')

/**
 * クエスト画面表示用に日付を変換する
 * @param date 日付
 */
export const convertQuestDate = (date: string | number | dayjs.Dayjs | Date | null | undefined) =>
  dayjs.tz(date, TERM_TIME_ZONE).format('M/D HH:mm')
