import { useRouter } from 'next/router'
import { Props } from './Layout'

export const useProps = (): Props => {
  const pathname = useRouter().pathname
  const activeTab: Props['activeTab'] = pathname.startsWith('/wallet') ? 'wallet' : pathname.startsWith('/ranking') ? 'ranking' : 'home'
  return {
    activeTab,
  }
}
