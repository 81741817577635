import React, { PropsWithChildren } from 'react'

export const HStack: React.FC<
  PropsWithChildren<{ className?: string | undefined; onClick?: () => void }>
> = ({ children, className, onClick }) => {
  return (
    <div className={`flex flex-row ${className ?? ''} relative`} onClick={onClick}>
      {children}
    </div>
  )
}
